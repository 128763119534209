<script>
import Swal from 'sweetalert2'
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

import moment from 'moment';


import { ref } from "vue";


/**
 * Dashboard Component
 */
export default {
    setup() {
        const doesApproveOpen = ref(false);
        const doesRejectOpen = ref(false);

        return {
          doesApproveOpen,
          doesRejectOpen
        };
    },
    page: {
    title: "Transactions",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Transactions",
      items: [
        {
          text: "Menu",
          href: "/",
        },
        {
          text: "Transactions",
          active: true,
        },
        {
          text: "Une transactions",
          active: true,
        },
      ],
      transaction: {},
      reason: "",
      pay_id: "",
      pay_date: "",
      id: "",
      loading: false,
      errors: []
    };
  },
  methods: {
    getItem() {
      fetch(Helper.route('transactions/'+this.id), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        
        this.transaction = data
      })
    },
    approuveTransaction() {
      this.loading = true
      this.errors = []
      let body = {
        "pay_date": this.pay_date,
        "pay_id": this.pay_id
      }
      fetch(Helper.route('transactions/'+this.transaction?.id+"/proof/sent"), Helper.requestOptionsJSON("POST", body))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        this.loading = false
        if (this.status == 201) {
          this.doesApproveOpen = false
          this.transaction = data
          Swal.fire("Transaction approuver !", "La transaction a été approuvé avec succès.", "success");
        } else {
            // this.errors.pay_date = Helper.getError(data, "pay_date")
            // this.errors.pay_id = Helper.getError(data, "pay_id")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    rejectTransaction() {
      this.loading = true
      this.errors = []
      let body = {
        "reason": this.reason
      }
      fetch(Helper.route('transactions/'+this.transaction?.id+"/reject"), Helper.requestOptionsJSON("POST", body))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        
        this.loading = false
        if (this.status == 201) {
          this.doesRejectOpen = false
          this.transaction = data
          Swal.fire("Transaction réjéter !", "La transaction a été réjétée avec succès.", "success");
        } else {
            // this.errors.reason = Helper.getError(data, "reason")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    convertDate(date) {
      return moment.utc(date).format('DD/MM/YYYY ') + " à " + moment.utc(date).format(' H:mm')
    },
    initItem(transaction, action="edit") {
      this.transaction = transaction
      this.initData(transaction)
      if (action == "delete") {
        this.doesDeleteOpen = true
      } else {
        this.doesEditOpen = true
      }
    },
    initData(item = null) {
      this.rate = item != null ? item.rate : ""
      this.to_id = item != null ? item.to.id : ""
      this.from_id = item != null ? item.from.id : ""
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.getItem()
  },
  watch: {
    doesAddOpen: function() {
      this.errors = []
      if (!this.doesAddOpen) {
        this.initData(null)
      }
    },
    doesEditOpen: function() {
      this.errors = []
      if (!this.doesEditOpen) {
        this.initData(null)
        this.transaction = null
      }
    },
    doesDeleteOpen: function() {
      if (!this.doesDeleteOpen) {
        this.initData(null)
        this.transaction = null
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    
    <div class="row g-4 mt-3" v-if="transaction?.id">
      <div class="col-lg-12">
        <h4 class="card-title text-danger">Client</h4>
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-md-3">
                <dl>
                  <dt>Nom de Famille</dt>
                  <dd>{{ transaction?.user.last_name }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Prénom(s)</dt>
                  <dd>{{ transaction?.user.first_name }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Email</dt>
                  <dd>{{ transaction?.user.email }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Le compte</dt>
                  <dd>
                    <router-link :to="'/users/'+transaction?.user.id">
                      Visiter le compte
                    </router-link>
                  </dd>
                </dl>
              </div>
            </div>


          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <h4 class="card-title text-danger">Transactions</h4>
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-md-4">
                <dl>
                  <dt>Date de la Transaction</dt>
                  <dd>{{ convertDate(transaction?.created_at) }}</dd>
                </dl>
              </div>
              <div class="col-md-4">
                <dl>
                  <dt>ID Transaction</dt>
                  <dd>{{ transaction?.transaction_id }}</dd>
                </dl>
              </div>
              <div class="col-md-4">
                <dl>
                  <dt>Nom</dt>
                  <dd>{{ transaction?.name }}</dd>
                </dl>
              </div>
              <hr>
              <div class="col-md-4">
                <dl>
                  <dt>De </dt>
                  <dd>
                    {{ transaction?.from_account }}
                  </dd>
                </dl>
              </div>
              <div class="col-md-4">
                <dl>
                  <dt>À </dt>
                  <dd>
                    {{ transaction?.to_account }}
                  </dd>
                </dl>
              </div>
              <div class="col-md-4">
                <dl>
                  <dt>Téléphone </dt>
                  <dd>
                    {{ transaction?.to_phone }}
                  </dd>
                </dl>
              </div>
              <hr>
              <div class="col-md-4">
                <dl>
                  <dt>Montant envoyé </dt>
                  <dd>
                    {{ transaction?.send_sold }} {{ transaction?.conversion?.from?.currency?.code }}
                  </dd>
                </dl>
              </div><div class="col-md-4">
                <dl>
                  <dt>Montant reçu </dt>
                  <dd>
                    {{ transaction?.receive_sold }} {{ transaction?.conversion?.to?.currency.code }}
                  </dd>
                </dl>
              </div><div class="col-md-4">
                <dl>
                  <dt>Gain </dt>
                  <dd>
                    {{ transaction?.gain_from }}  {{ transaction?.conversion?.from?.currency.code }}
                  </dd>
                </dl>
              </div>
              <hr>
              <div class="col-md-4">
                <dl>
                  <dt>Conversion de </dt>
                  <dd>
                    {{ transaction?.conversion?.from.name }}
                  </dd>
                </dl>
              </div><div class="col-md-4">
                <dl>
                  <dt>Conversion à </dt>
                  <dd>
                    {{ transaction?.conversion?.to.name }}
                  </dd>
                </dl>
              </div><div class="col-md-4">
                <dl>
                  <dt>Statut de la Transaction</dt>
                  <dd>
                    <span v-if="transaction?.status == 'EN ATTENTE' || transaction?.status == 'CANCEL'" class="badge badge-pill badge-soft-danger font-size-11">
                          {{ transaction?.status }}
                      </span>
                      <span v-if="transaction?.status == 'PROUVEE' || transaction?.status == 'EN COURS'" class="badge badge-pill badge-soft-orange font-size-11">
                          {{ transaction?.status }}
                      </span>
                      <span v-if="transaction?.status == 'ECHOUE'" class="badge badge-pill badge-soft-danger font-size-11">
                          {{ transaction?.status }}
                      </span>
                      <span v-if="transaction?.status == 'TERMEINE' || transaction?.status == 'TRANSFERER'" class="badge badge-pill badge-soft-success font-size-11">
                          {{ transaction?.status }}
                      </span>
                  </dd>
                </dl>
              </div>
              <template v-if="transaction?.reason">
                <hr>
                <div class="col-md-12">
                  <dl>
                    <dt>Raison du rejet </dt>
                    <dd>
                      {{ transaction?.reason }}
                    </dd>
                  </dl>
                </div>
              </template>
            </div>

          </div>
        </div>
      </div>

      <div class="col-lg-12" v-if="transaction?.user_pay_id">
        <h4 class="card-title text-danger">Preuve du client</h4>
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-md-4">
                <dl>
                  <dt>Id Du Paiement</dt>
                  <dd>{{ transaction?.user_pay_id }}</dd>
                </dl>
              </div>
              <div class="col-md-4">
                <dl>
                  <dt>Date de Paiement</dt>
                  <dd>{{ transaction?.user_pay_date }}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div class="hstack mb-4" v-if="transaction?.status == 'PROUVEE' || transaction?.status == 'EN COURS'">
          <button class="btn btn-success" @click="doesApproveOpen = true">Approuver</button>
          <button class="btn btn-danger ms-4" @click="doesRejectOpen = true">Réjéter</button>
        </div>
      </div>

      <div class="col-lg-12" v-if="transaction?.status == 'TRANSFERER'">
        <h4 class="card-title text-danger">Preuve de NguizaPay</h4>
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-md-4">
                <dl>
                  <dt>Id Du Paiement</dt>
                  <dd>{{ transaction?.pay_id }}</dd>
                </dl>
              </div>
              <div class="col-md-4">
                <dl>
                  <dt>Date de Paiement</dt>
                  <dd>{{ convertDate(transaction?.pay_date) }}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      

    </div>




    
    <!-- APPROVE MODAL -->
    <b-modal
        v-model="doesApproveOpen"
        id="modal-center"
        centered
        size="md"
        title="Approuver la transaction"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="approuveTransaction()">
          <div class="row">
            <div class="col-md-12 mb-3">
                <label for="">Date de Paiement</label>
                <input type="datetime-local" required v-model="pay_date" class="form-control">
              <span class="text-danger mt-2" v-if="errors.rate">{{ errors.pay_date }}</span>
            </div>
            <div class="col-md-12 mb-3">
              <label for="">ID de Paiement </label>
              <input type="text" required v-model="pay_id" class="form-control">
              <span class="text-danger mt-2" v-if="errors.rate">{{ errors.pay_id }}</span>
            </div>
          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Approuver" class="btn btn-success">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>


    <!-- REJECT MODAL -->
    <b-modal
        v-model="doesRejectOpen"
        id="modal-center"
        centered
        size="md"
        title="Réjéter la transaction"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="rejectTransaction()">
          <div class="row">
            <div class="col-md-12 mb-3">
                <label for="">Raison du rejet</label>
                <textarea rows="3" required v-model="reason" class="form-control"></textarea>
              <span class="text-danger mt-2" v-if="errors.reason">{{ errors.reason }}</span>
            </div>
          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Réjéter" class="btn btn-danger">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>


  </Layout>
</template>
